import React from "react"
import PropTypes from "prop-types"

import { rhythm } from "../../utils/typography"
import LazySVGImg from "../../components/lazy-svg"

const Columns = ({ section }) => {
  const Keyvisual = section.keyvisual && section.keyvisual.name

  return (
    <div
      css={theme => ({
        [theme.mediaQueries.tablet]: {
          display: `flex`,
          flexDirection: `column`,
          flexGrow: 0,
          flexShrink: 0,
          width: `50%`,
          padding: `0 ${theme.space[13]}`,
        },
      })}
    >
      <div
        css={theme => ({
          display: `flex`,
          alignItems: `flex-end`,
          [theme.mediaQueries.tablet]: {
            minHeight: 240,
          },
        })}
      >
        <h2
          css={theme => ({
            color: theme.colors.white,
            display: `inline-block`,
            flexGrow: 1,
            marginBottom: theme.space[7],
            marginRight: `auto`,
            marginTop: theme.space[10],
            paddingRight: theme.space[10],
            WebkitFontSmoothing: `antialiased`,
            [theme.mediaQueries.phablet]: {
              maxWidth: rhythm(16),
            },
          })}
        >
          {section.title}
        </h2>
        {Keyvisual && (
          <LazySVGImg
            src={`${Keyvisual}.svg`}
            alt={``}
            css={{
              flexShrink: 1,
              minWidth: 80,
            }}
          />
        )}
      </div>
      <div
        css={theme => ({
          "& p": {
            color: theme.colors.purple[30],
            maxWidth: rhythm(22),
            WebkitFontSmoothing: `antialiased`,
          },
        })}
        dangerouslySetInnerHTML={{
          __html: section.text && section.text.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

Columns.propTypes = {
  section: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

export default Columns
